import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { IoAppsSharp } from "react-icons/io5"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/section"
import ExamPrograms from "../../components/exam-programs"
import examPrograms from "../../static/exam-programs"
import { setCurrentPage } from "../../states/slices/main-slice"

const PlaygroundIndex = () => {
  const { lang } = useSelector(({ mainReducer }) => mainReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `playground-index`,
        group: `app`,
      })
    )
  }, [dispatch])

  return (
    <Layout>
      <Seo title={examPrograms.title[lang]} />
      <Section title={examPrograms.title[lang]} icon={<IoAppsSharp />}>
        <ExamPrograms />
      </Section>
    </Layout>
  )
}

export default PlaygroundIndex
